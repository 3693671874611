export default {
    props: {
        info: {
            required: true,
            type: Object  // required keys: cover, name, subName, purchaseType, price, isPreOrder, suitable, difficultyLevel
        },
    },
    computed: {
        courseUtil() {
            return this.$store.state.course.courseUtil;
        }
    }
}

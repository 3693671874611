/**
 * 回傳說明頁面狀態各數值對應的文字
 * @param     string  val
 *
 * @return    string  對應的說明頁面狀態
 */
function setStateText(val) {
    switch (val) {
        case '0':
            return '編輯中';
        case '1':
            return '待審核';
        case '2':
            return '已排程';
        case '3':
            return '上架中';
        case '4':
            return '已封存';
    }
}


const methods = {
    setStateText
}

export default methods

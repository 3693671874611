import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allUserIds'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            userList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false  // 是否全部載入完畢
        }
    },
    computed: {
        tableColumnList() {
            let list = [];
            list = [
                {
                    key: "id",
                    header: "ID",
                    width: "5%",
                    isShow: true
                },
                {
                    key: "name",
                    header: "姓名",
                    width: "12%",
                    isShow: true
                },
                {
                    key: "account",
                    header: "帳號",
                    width: "20%",
                    isShow: true
                },
                {
                    key: "type",
                    header: "類別",
                    width: "8%",
                    isShow: true
                },
                {
                    key: "permission",
                    header: "權限",
                    width: "8%",
                    isShow: true
                },
                {
                    key: "school",
                    header: "學校",
                    width: "15%",
                    isShow: true
                },
                {
                    key: "registerDate",
                    header: "註冊日",
                    width: "12%",
                    isShow: true
                },
                {
                    key: "validated",
                    header: "已驗證",
                    width: "5%",
                    isShow: true
                },
                {
                    key: "deleted",
                    header: "帳號狀態",
                    width: "10%",
                    isShow: true
                },
                {
                    key: "advanced",
                    header: "進階",
                    width: "5%",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);

            return list;
        }
    },
    created: function() {
        if (this.allUserIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        async loadMore() {
            if (this.isGetAllList) {
                return;
            }

            this.busy = true;
            await this.getUserList();
            this.busy = false;
        },
        getUserList() {
            return new Promise((resolve, reject) => {
                let uIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let uId = this.allUserIds[this.loadingIndex];
                    if (!uId) {
                        this.isGetAllList = true;
                        break;
                    }
                    uIds.push(uId);
                    this.loadingIndex++;
                }

                if (!uIds.length) {
                    resolve();
                    return;
                }

                let params = {
                    userIds: uIds
                }

                this.$httpRequest.post('/admin_api/user/get_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for (let uId of params.userIds) {
                                    let u = result[uId];

                                    if (!u) {
                                        continue;
                                    }

                                    this.userList.push({
                                        id: uId,
                                        name: u.name,
                                        account: u.account,
                                        type: u.type,
                                        permission: u.permission,
                                        school: u.org_name,
                                        registerDate: this.$util.unixTimestampToDatetime(u.enterdate, this.$util.getBrowserCurrentTimeZone(), 'datetime'),
                                        validated: u.is_validated,
                                        deleted: u.deleted
                                    });
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        }
    }
}

export default {
    props: [
        'courseInfo',
        'isDoingAddToGiftOrdersAction',
        'isAddingItemsToGiftOrders',
        'isDoingAddToOrdersAction',
        'isAddingItemsToOrders'
    ],
    data: function() {
        return {
            lastSelectedPlanId: '0'
        }
    },
    computed: {
        selectedPrice: {
            get() {
                return this.$store.state.course.selectedPrice;
            },
            set(value) {
                this.$store.commit('course/setSelectedPrice', value);
            }
        }
    },
    methods: {
        selectPlan(planId) {
            // 若目前選取為上一次選取的項目, 則取消勾選
            if (planId === this.lastSelectedPlanId) {
                $(`#subPlanItem${planId}`).prop('checked', false);
                this.lastSelectedPlanId = '0';
                this.selectedPrice = {
                    id: '0',
                    type: 'price',
                    unit: '1',
                    price: this.courseInfo.price,
                    planName: ''
                }
                return;
            }

            this.lastSelectedPlanId = planId;
        }
    }
}

import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allCourseIds'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            courseList: [],

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 10,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    computed: {
        courseUtil() {
            return this.$store.state.course.courseUtil;
        },
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "select",
                    itemText: "選取",
                    ownClass: "item-select",
                    isShow: true
                },
                {
                    itemName: "id",
                    itemText: "ID",
                    ownClass: "item-id",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "課程名稱",
                    ownClass: "item-name",
                    isShow: true
                },
                {
                    itemName: "state",
                    itemText: "狀態",
                    ownClass: "item-state",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        subPageStoreModuleName() {
            switch (this.$route.params.subPage) {
                // 首頁橫幅
                case 'banner':
                    return 'adminPnBanner';
                // 平台公告
                case 'announcement':
                    return 'adminPnAnnouncement';
            }

            return '';
        },
        linkedResourceInfo: {
            get() {
                return this.$store.state[this.subPageStoreModuleName].linkedResourceInfo;
            },
            set(value) {
                this.$store.commit(`${this.subPageStoreModuleName}/setLinkedResourceInfo`, value);
            }
        }
    },
    created: function() {
        if (this.allCourseIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getCourseList()
                    .then(() => this.busy = false)
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getCourseList() {
            return new Promise((resolve, reject) => {
                let courseIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempCourseId = this.allCourseIds[this.loadingIndex];
                    if (!tempCourseId) {
                        this.isGetAllList = true;
                        break;
                    }
                    courseIds.push(tempCourseId);
                    this.loadingIndex++;
                }

                if (courseIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    courseIds: courseIds
                }

                this.$httpRequest.post('/admin_api/course/get_course_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    courseIds.forEach(id => {
                                        let tempCourse = result[id];
                                        if (tempCourse) {
                                            this.courseList.push({
                                                id: tempCourse.id,
                                                state: tempCourse.state,
                                                name: tempCourse.name
                                            });
                                        }
                                    });
                                }

                                resolve();
                            } else {
                                reject("admin/push_notification/announcement/_course_table_list.js: 'getCourseList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/push_notification/announcement/_course_table_list.js: 'getCourseList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("admin/push_notification/announcement/_course_table_list.js: 'getCourseList' get error");
                    });
            });
        }
    }
}

// components
const TeachTableList = resolve => require(["@/components/admin/user/_teach_table_list.vue"], resolve)
const CourseTableList = resolve => require(["@/components/admin/user/_course_table_list.vue"], resolve)
const OrderTableList = resolve => require(["@/components/admin/user/_order_table_list.vue"], resolve)
const ValidatePwd = resolve => require(["@/components/account/_validate_pwd.vue"], resolve)

export default {
    components: {
        TeachTableList,
        CourseTableList,
        OrderTableList,
        ValidatePwd
    },
    data: function() {
        return {
            activeTab: 'user',
            user: [],
            renderTeachTableListCount: 0,
            renderCourseTableListCount: 0,
            renderOrderTableListCount: 0,
            teaches: [],
            courses: [],
            orders: [],
            allCourseIds: [],
            allOrderIds: [],

            newPassword: '',
            isPwdValid: {
                length: false,
                includeNum: false
            },

            isPostingApi: {
                updatePassword: false,  // 更新使用者密碼
            }
        }
    },
    computed: {
        tabSelectedList() {
            let tabArr = [];
            tabArr = [
                {
                    value: 'user',
                    text: '帳號資料'
                },
                {
                    value: 'teach',
                    text: '教授課程'
                },
                {
                    value: 'course',
                    text: '課程列表'
                },
                {
                    value: 'order',
                    text: '訂單列表'
                }
            ]

            // 如果選擇的使用者之權限為"user"，那進階的編輯畫面不需要[教授課程]tab
            if (this.user.permission == 'user') {
                tabArr.splice(1, 1);
            }

            return tabArr;
        },
        permissionList() {
            let inputArr = [];
            inputArr = [
                {
                    value: 'user',
                    text: '一般使用者User'
                },
                {
                    value: 'teacher',
                    text: '開課者Teacher'
                },
                {
                    value: 'admin',
                    text: '管理者Admin'
                }
            ]

            return inputArr;
        },
        pwdError() {
            return !Object.values(this.isPwdValid).every(val => val === true);
        }
    },
    created: function() {
        this.getUser();
    },
    methods: {
        changeActiveTab(tab) {
            this.activeTab = tab.value;

            switch(this.activeTab) {
                case 'user':
                    // 使用者基本資料
                    this.getUser();
                    break;
                case 'teach':
                    // 老師教授課程
                    this.getTeaches();
                    break;
                case 'course':
                    // 購買課程
                    this.getCourses();
                    break;
                case 'order':
                    // 訂單資料
                    this.getOrders();
                    break;
            }
        },
        getUser() {
            let params = {
                userIds: [this.$route.params.id]
            }

            this.$httpRequest.post('/admin_api/user/get_list_info', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            let u = result[this.$route.params.id];

                            this.user = {
                                id: u.id,
                                name: u.name,
                                type: u.type,
                                account: u.account,
                                permission: u.permission,
                                birth: u.birth,
                                gender: u.gender,
                                country: u.country,
                                counties: u.counties,
                                eduLevel: u.edu_level,
                                school: u.org_name,
                                class: u.class,
                                classNo: u.class_no,
                                buyerName: u.buyer_name,
                                buyerPhone: u.buyer_phone,
                                buyerTelephone: u.buyer_telephone,
                                contactEmail: u.contact_email,
                                deleted: u.deleted
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        getTeaches() {
            let params = {
                userId: this.user.id
            }

            this.$httpRequest.get('/admin_api/course/get_editable_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allCourseIds = result;
                            this.renderTeachTableListCount++;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        getCourses() {
            this.courses = {
                id : 1,
                type : 'facebook',
                name : 'bear',
                username : 'userName@facebook.com',
                permission : 'admin'
            }
        },
        getOrders() {
            this.orders = {
                id : 1,
                type : 'facebook',
                name : 'bear',
                username : 'userName@facebook.com',
                permission : 'admin'
            }
        },
        updateProfile() {
            let params = {
                userId: this.user.id,
                permission: this.user.permission
            }

            this.$httpRequest.post('/admin_api/user/update_profile', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });

                            if (result.is_me) {
                                this.$store.dispatch('common/updateUserInfo', { permission: this.user.permission });
                                this.user.permission == 'teacher' ? this.$router.push('/admin/course/list/all') : this.$router.push('/');
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        updatePassword() {
            if (this.pwdError || this.isPostingApi.updatePassword) {
                return;
            }

            let params = {
                userId: this.user.id,
                type: this.user.type,
                account: this.user.account,
                password: this.newPassword
            }

            this.isPostingApi.updatePassword = true;

            this.$httpRequest.post('/admin_api/user/update_password', params)
                .then(response => {
                    this.isPostingApi.updatePassword = false;

                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });

                        this.newPassword = '';
                    }
                })
                .catch(error => {
                    this.isPostingApi.updatePassword = false;
                    console.error('Catched Error:', error);
                });
        }
    }
}

import InfiniteScroll from "vue-infinite-scroll"
// common
import Dialogue from "@/components/common/dialogue.vue"
export default {
    props: ['allStudentIds', 'page', 'classNameList', 'nowClassName', 'nowStudentIds'],
    directives: {
        InfiniteScroll
    },
    components: {
        Dialogue
    },
    data: function() {
        return {
            inputKeyword: '',
            studentList: [],
            oldStudentList: [],
            isCheckedAll: false,
            selectedClassName: this.nowClassName ? this.nowClassName : '',

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 10,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    watch: {
        inputKeyword(newValue) {
            if (!newValue) {
                this.$parent.$parent.getStudentList();
            }
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "check",
                    itemText: "",
                    ownClass: "check",
                    isShowCheckbox: true,
                    isShow: true
                },
                {
                    itemName: "class",
                    itemText: "班級",
                    ownClass: "class",
                    isShowDropDown: true,
                    isShow: true
                },
                {
                    itemName: "class-no",
                    itemText: "座號",
                    ownClass: "class-no",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "姓名",
                    ownClass: "name",
                    isShow: true
                },
                {
                    itemName: "account",
                    itemText: "帳號",
                    ownClass: "account",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        oidcStudentIds: {
            get() {
                return this.$store.state.user.oidcStudentIds;
            },
            set(value) {
                this.$store.commit('user/setOidcStudentIds', value);
            }
        },
    },
    created: function() {
        if (this.allStudentIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getStudentList()
                    .then(() => {
                        this.busy = false
                    })
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getStudentList() {
            return new Promise((resolve, reject) => {

                let studentIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempStudentId = this.allStudentIds[this.loadingIndex];
                    if (!tempStudentId) {
                        this.isGetAllList = true;
                        break;
                    }
                    studentIds.push(tempStudentId);
                    this.loadingIndex++;
                }

                if (studentIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    userIds: studentIds
                }

                this.$httpRequest.post('/api/group/get_school_student_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    for(let id of studentIds) {
                                        let tempStudent = result[id];
                                        if (tempStudent) {
                                            this.studentList.push({
                                                id : id,
                                                class: tempStudent.class,
                                                classNo: tempStudent.classNo,
                                                name: tempStudent.name,
                                                account: tempStudent.account
                                            });
                                        }
                                    }
                                    this.oldStudentList = this.$_.cloneDeep(this.studentList);
                                }
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        selectAllCheckbox() {
            this.isCheckedAll = !this.isCheckedAll;
            if (this.isCheckedAll == true) {
                for(let el of this.studentList) {
                    this.oidcStudentIds.push(el.id);
                }
            }
            else {
                this.oidcStudentIds = [];
            }
        },
        selectName(name) {
            if (!name) {
                this.selectedClassName = '';
                this.$parent.$parent.getStuedntList('');
            }
            else {
                this.selectedClassName = name;
                this.$parent.$parent.getStuedntList(name);
            }
        }
    }
}

export default {
    props: {
        groupNo: {
            type: Number,
            default: 0,
        },
        quesNo: {
            required: true,
            type: Number,
        },
        quesType: {
            required: true,
            type: String,
        },
        score: {
            type: String,
            default: '',
        },
        progress: {
            type: String,
            default: '',
        },
    },
}

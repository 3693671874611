// components
const ValidatePwd = resolve => require(["@/components/account/_validate_pwd.vue"], resolve)

export default {
    props: {
        formType: {
            required: true,
            type: String,
            validator: value => {
                return ['login', 'register', 'resetPWD'].includes(value)
            }
        },
        accountType: {
            required: true,
            type: String,
            validator: value => {
                return ['phone', 'email'].includes(value)
            }
        },
        account: {
            required: true,
            type: String
        },
        verificationCode: {
            type: String,
            default: ''
        },
        loginConfirming: {
            type: Boolean,
            default: false
        },
        loginFail: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ValidatePwd
    },
    data: function() {
        return {
            password1: '',
            password2: '',
            isPwd1Valid: {
                length: false,
                includeNum: false
            },

            isPostingApi: {
                confirm: false,  // 註冊新帳號 | 密碼重置
            }
        }
    },
    watch: {
        loginFail(newValue) {
            if (newValue) {
                this.password1 = '';  // 密碼錯誤則清空密碼欄位, 以便使用者再次填寫
            }
        }
    },
    computed: {
        pwd1Error() {
            return !Object.values(this.isPwd1Valid).every(val => val === true);
        },
        pwd2Error() {
            return !!(this.password2 && (this.password1 !== this.password2));
        }
    },
    created: function() {
        this.$emit('update:loginConfirming', false);
        this.$emit('update:loginFail', false);

        this.$nextTick(() => {
            const pwd1Elem = document.getElementById('pwd1Input');
            pwd1Elem && pwd1Elem.focus();
        });
    },
    methods: {
        login() {
            if (!this.password1) {
                return;
            }

            this.$emit('update:loginFail', false);
            this.$emit('login', this.password1);
        },
        register() {
            if (!this.password1 || !this.password2 || this.pwd1Error || this.pwd2Error || this.isPostingApi.confirm) {
                return;
            }

            let params = {
                type: this.accountType,
                account: this.account,
                pwdUUID: this.password1,
                name: '新用戶'
            };

            this.isPostingApi.confirm = true;

            this.$httpRequest.post('/api/user/email_phone_register', params)
                .then(response => {
                    this.isPostingApi.confirm = false;

                    // 註冊成功, 導向帳號驗證頁面
                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });

                        this.$emit('verifyAccount', 'auth');
                    }
                })
                .catch(error => {
                    this.isPostingApi.confirm = false;
                    console.error('Catched Error:', error);
                });
        },
        resetPassword() {
            if (!this.password1 || !this.password2 || this.pwd1Error || this.pwd2Error || this.isPostingApi.confirm) {
                return;
            }

            let params = {
                type: this.accountType,
                account: this.account,
                password: this.password1,
                authCode: this.verificationCode
            };

            this.isPostingApi.confirm = true;

            this.$httpRequest.post('/api/user/reset_password', params)
                .then(response => {
                    this.isPostingApi.confirm = false;

                    // 重設密碼成功, 導向入口登入頁面
                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });

                        this.$emit('update:verificationCode', '');
                        this.$emit('returnToEntry');
                    }
                })
                .catch(error => {
                    this.isPostingApi.confirm = false;
                    console.error('Catched Error:', error);
                });
        }
    }
}

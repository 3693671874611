import { render, staticRenderFns } from "./image_previewer.vue?vue&type=template&id=0fd8188a&scoped=true&"
import script from "@/assets/js/components/article/practicing/components/image_previewer.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/article/practicing/components/image_previewer.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/article/practicing/components/image_previewer.scss?vue&type=style&index=0&id=0fd8188a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fd8188a",
  null
  
)

export default component.exports
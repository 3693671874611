export default {
    props: {
        dialogueId: {
            type: String,
            default: 'sampleImagePreviewer',
        },
        image: {
            required: true,
            type: String,
        },
    },
    methods: {
        closeDialogue() {
            $(`#${this.dialogueId}`).modal('hide');
        },
    },
}

import draggable from "vuedraggable"
// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const ArticleTableList = resolve => require(["@/components/admin/course/edit/_article_table_list.vue"], resolve)

export default {
    props: ['courseBasicInfo'],
    components: {
        draggable,
        Dialogue,
        ArticleTableList
    },
    data: function() {
        return {
            activeTab: 'articles',

            allArticleIds: [],
            searchText: '',
            renderTableListKey: 0,

            categoryList: [],
            oldCategoryList: [],
            defaultCategory: null,
            selectedCategoryIndex: null,
            oldCategoryName: '',
            newCategoryName: '',
            isNewCategoryNameRepeat: false,
            inputCategoryNameRepeatMsg: '分類名稱不可重複，請重新命名',
            combineCategories: [],
            deletedCategoryIds: [],

            isGettingApi: {
                getArticleIdsByDefault: false  // 取得文章 ids
            },
            isSetDataReady: {
                getCategoryList: false  // 取得課程分類列表資料
            }
        }
    },
    watch: {
        '$parent.isClickSidebarLink'(newValue) {
            if (newValue) {
                this.getArticleIdsByDefault();
            }
            this.$parent.isClickSidebarLink = false;
        }
    },
    computed: {
        editArticlesTabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'articles',
                    text: '課程文章'
                },
                {
                    value: 'categories',
                    text: '分類管理'
                }
            ]
            return tabs;
        },
        categoriesTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "no",
                    itemText: "排序",
                    ownClass: "article-category-no",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "分類名稱",
                    ownClass: "article-category-name",
                    isShow: true
                },
                {
                    itemName: "total",
                    itemText: "文章量",
                    ownClass: "article-category-total",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "article-category-advanced",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        currentCategoryNames() {
            let ary = [];
            // 訂閱制課程預設第一筆分類為"最新"
            if (this.courseBasicInfo.purchaseType == '0') {
                ary.push('最新');
            }
            this.categoryList.forEach(el => ary.push(el.name));  // 自訂分類
            ary.push('其他');  // 預設分類
            return ary;
        },
        updateCourseData() {
            if (!this.isSetDataReady.getCategoryList) {
                return;
            }

            let updateData = {};

            // 課程文章 - 分類管理
            // 判斷分類列表是否有做新增, 更名, 異動順序或刪除項目
            let tempCategoryIds = [], oldCategoryIds = [];
            this.categoryList.forEach(el => tempCategoryIds.push(el.id));
            this.oldCategoryList.forEach(el => oldCategoryIds.push(el.id));
            let diffCategoryIds = this.$_.difference(tempCategoryIds, oldCategoryIds);

            let diffCategoryNames = this.$_.differenceBy(this.categoryList, this.oldCategoryList, 'name');

            // 有新增, 更名或異動順序過的分類
            // 將兩陣列 join 為字串是用來判斷分類列表是否有做重新排序
            if (diffCategoryIds.length ||
                diffCategoryNames.length ||
                (tempCategoryIds.join('') !== oldCategoryIds.join(''))) {
                updateData.categories = this.categoryList;
            }
            // 有刪除的分類
            if (this.deletedCategoryIds.length) {
                updateData.deleteIds = this.deletedCategoryIds;
            }

            updateData.saveType = 'articles';  // 指定儲存類型

            return updateData;
        },
        isArticlePublicEditable() {
            // 是否可以編輯文章"公開度"
            // 1. admin 不管課程狀態為何, 皆可編輯以上項目
            // 2. teacher 僅只能在課程狀態為"編輯中"時, 才能編輯以上項目
            return this.$store.state.common.userInfo.permission == 'admin' ||
                    (this.$store.state.common.userInfo.permission == 'teacher' && this.courseBasicInfo.state == '0');
        }
    },
    created: function() {
        // 如果從文章過來新增分類，activeTab必須將分類tab打開
        if (this.$route.query.active == 'addCategory') {
            this.activeTab = 'categories';
        }
        this.initialize();
    },
    mounted: function() {
        // 彈窗打開後直接 focus 輸入框
        $('#addCategoryDialogue').on('shown.bs.modal', () => {  // 新增分類
            $('#addCategoryInput').focus();
        });
        $('#renameCategoryDialogue').on('shown.bs.modal', () => {  // 重新命名分類
            $('#renameCategoryInput').focus();
        });

        // 彈窗打開有按下 enter 按鍵後做對應動作
        $("#addCategoryDialogue").keypress(event => {  // 新增分類
            let keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == '13') {
                this.addCategory();
            }
        });
        $("#renameCategoryDialogue").keypress(event => {  // 重新命名分類
            let keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == '13') {
                this.renameCategory();
            }
        });
        $("#combineCategoryDialogue").keypress(event => {  // 合併分類
            let keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == '13') {
                this.combineCategory();
            }
        });

        // 如果從文章過來新增分類，需打開新增分類的pop up
        if (this.$route.query.active == 'addCategory') {
            this.showAddCategoryDialogue();
            // 清除query參數
            this.$router.replace({'query': null});
        }
    },
    methods: {
        initialize() {
            switch (this.activeTab) {
                case 'articles':
                    this.$parent.isClickSidebarLink = false;
                    this.getArticleIdsByDefault();
                    break;
                case 'categories':
                    var refreshCategoryList = () => {
                        this.$parent.isFinishUpdatingData = false;
                        this.isSetDataReady.getCategoryList = false;
                        this.oldCategoryList = [];
                        this.deletedCategoryIds = [];

                        this.getCategoryList()
                            .then(() => {
                                this.isSetDataReady.getCategoryList = true;

                                // 監聽編輯內容是否更動
                                this.$watch('updateCourseData', newValue => {
                                    this.$store.commit('adminCourse/setUpdateCourseData', newValue);
                                    this.$store.commit('adminCourse/setIsShowNotSaveCoursePrompt', !this.$_.isEmpty(this.$_.omit(newValue, ['saveType'])));
                                });
                            })
                            .catch(error => console.log('catched error: ' + error));
                    }

                    refreshCategoryList();

                    // 當成功更新課程分類後, 則重新取得分類列表資料
                    this.$watch('$parent.isFinishUpdatingData', newValue => {
                        if (newValue) {
                            refreshCategoryList();
                        }
                    });
                    break;
            }
        },
        changeActiveTab(newTab) {
            // 如果有尚未儲存的項目, 需跳出提示彈窗提醒使用者
            if (!this.$_.isEmpty(this.$_.omit(this.updateCourseData, ['saveType']))) {
                if (window.confirm('系統可能不會儲存您所做的變更。')) {
                    this.categoryList = this.oldCategoryList;
                } else {
                    return;
                }
            }
            this.activeTab = newTab;
            this.initialize();
        },

        getArticleIdsByDefault() {
            if (!this.$route.params.id ||
                this.activeTab !== 'articles' ||
                this.isGettingApi.getArticleIdsByDefault) {
                return;
            }

            let params = {
                courseId: this.$route.params.id,
                state: -1  // 全部狀態的文章
            }

            this.allArticleIds = [];
            this.searchText = '';

            this.isGettingApi.getArticleIdsByDefault = true;

            this.$httpRequest.get('/admin_api/article/get_article_ids', params)
                .then(response => {
                    this.isGettingApi.getArticleIdsByDefault = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allArticleIds = result;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        getArticleIdsBySearchText() {
            if (!this.$route.params.id || !this.searchText) {
                return;
            }

            let params = {
                courseId: this.$route.params.id,
                key: this.searchText,
                state: -1  // 全部狀態的文章
            }

            this.allArticleIds = [];

            this.$httpRequest.get('/admin_api/article/get_article_ids_by_search', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allArticleIds = result;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },

        getCategoryList() {
            if (!this.$route.params.id) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    courseId: this.$route.params.id
                }

                this.categoryList = [];

                this.$httpRequest.get('/admin_api/course/get_catagories', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                result.map(el => {
                                    el.total = parseInt(el.total);
                                });

                                this.categoryList = this.$_.cloneDeep(result);
                                this.categoryList = this.$_.dropRight(this.categoryList, 1);
                                this.categoryList.map(el => {
                                    el.combine = [];
                                });

                                this.defaultCategory = this.$_.last(result);  // 其他

                                // 暫存原本的分類資料, 以用來判斷是否enable儲存按鈕
                                this.oldCategoryList = this.$_.cloneDeep(this.categoryList);

                                resolve();
                            } else {
                                reject("admin/course/edit/articles.js: 'getCategoryList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/course/edit/articles.js: 'getCategoryList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("admin/course/edit/articles.js: 'getCategoryList' get error");
                    });
            });
        },
        showAddCategoryDialogue() {
            if (this.categoryList.length >= 32) {
                return;
            }

            this.newCategoryName = '';
            this.isNewCategoryNameRepeat = false;
            $('#addCategoryDialogue').modal('show');
        },
        addCategory() {
            if (!this.newCategoryName || this.isNewCategoryNameRepeat) {
                return;
            }

            let newItem = {
                id: null,
                name: this.newCategoryName,
                total: 0,
                combine: []
            }
            this.categoryList.push(newItem);
            $('#addCategoryDialogue').modal('hide');
            // 因為新增的項目在分類列表最後一個, 因此拉動捲軸至列表最下方
            this.$nextTick(() => $('#categoryTableList').scrollTop($('#categoryTableList')[0].scrollHeight));
        },
        showRenameCategoryDialogue(index, name) {
            this.selectedCategoryIndex = index;
            this.oldCategoryName = name;
            this.newCategoryName = name;
            $('#renameCategoryDialogue').modal('show');
        },
        renameCategory() {
            if (!this.newCategoryName || this.newCategoryName === this.oldCategoryName || this.isNewCategoryNameRepeat) {
                return;
            }

            this.categoryList.forEach((el, i) => {
                if (i === this.selectedCategoryIndex) {
                    el.name = this.newCategoryName;
                }
            });
            $('#renameCategoryDialogue').modal('hide');
        },
        showCombineCategoryDialogue(index, name) {
            this.selectedCategoryIndex = index;
            this.newCategoryName = name;
            this.combineCategories = [];
            $('#combineCategoryDialogue').modal('show');
        },
        combineCategory() {
            if (!this.combineCategories.length || !this.newCategoryName) {
                return;
            }

            let combineIds = [], combineIndexs = [], combineTotal = 0;
            this.combineCategories.forEach(el => {
                combineIds.push(el.id);
                combineIndexs.push(el.index);
                combineTotal += el.total;
            });
            this.categoryList.forEach((el, i) => {
                if (i === this.selectedCategoryIndex) {
                    el.name = this.newCategoryName;
                    el.combine = this.$_.compact(combineIds);  // 去除值為 null 的 element
                    el.total += combineTotal;
                }
            });
            // 移除被合併的分類
            this.categoryList = this.$_.remove(this.categoryList, (el, i) => {
                return !combineIndexs.includes(i);
            });

            $('#combineCategoryDialogue').modal('hide');
        },
        showDeleteCategoryDialogue(index) {
            this.selectedCategoryIndex = index;
            $('#deleteCategoryDialogue').modal('show');
        },
        deleteCategory() {
            this.categoryList = this.$_.remove(this.categoryList, (el, i) => {
                if (i === this.selectedCategoryIndex && el.id) {  // 欲刪除的分類為原本存在的分類
                    this.deletedCategoryIds.push(el.id);
                    this.defaultCategory.total += el.total;
                }
                return i !== this.selectedCategoryIndex;
            });
            $('#deleteCategoryDialogue').modal('hide');
        }
    }
}

export default {
    props: {
        isShowAppLinks: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        appDownload() {
            return this.$store.state.common.appDownload;
        }
    }
}

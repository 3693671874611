export default {
    props: {
        password: {
            required: true,
            type: String
        },
        isPwdValid: {
            required: true,
            type: Object
        }
    },
    watch: {
        password() {
            this.validatePassword();
        }
    },
    methods: {
        validatePassword() {
            let isValid = {};

            // 長度為6-20字元
            isValid['length'] = this.password.length >= 6 && this.password.length <= 20;

            // 包含一個數字
            isValid['includeNum'] = /[0-9]/.test(this.password);

            this.$emit('update:isPwdValid', isValid);
        }
    }
}

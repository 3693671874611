import InfiniteScroll from "vue-infinite-scroll"
// components
const UnsubscribePromptDialogue = resolve => require(["@/components/user/orders/_unsubscribe_prompt_dialogue.vue"], resolve)

export default {
    props: ['activeTab', 'allPaymentOrderNos', 'expandingOrderItem'],
    directives: {
        InfiniteScroll,
    },
    components: {
        UnsubscribePromptDialogue
    },
    data: function() {
        return {
            paymentDetailsList: [],

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢

            isFirstLoaded: false  // 是否第一次載入完成
        }
    },
    computed: {
        orderUtil() {
            return this.$store.state.order.orderUtil;
        },
        isGiftOrder() {
            return this.$store.state.user.isGiftOrder;
        },
        activeUnsubscribeOrder: {
            get() {
                return this.$store.state.user.activeUnsubscribeOrder;
            },
            set(value) {
                this.$store.commit('user/setActiveUnsubscribeOrder', value);
            }
        }
    },
    created: function() {
        // 訂閱制
        if (this.activeTab === 'subscribe' && this.allPaymentOrderNos.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
        // 課程制
        if (this.activeTab === 'purchased') {
            this.getPurchasedPaymentInfo();
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getSubscribePaymentList()
                    .then(() => {
                        this.busy = false;
                        if (!this.isFirstLoaded) {
                            this.isFirstLoaded = true;
                            this.$emit('firstLoaded');
                        }
                    })
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getSubscribePaymentList() {
            return new Promise((resolve, reject) => {
                let paymentOrderNos = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempPaymentOrderNo = this.allPaymentOrderNos[this.loadingIndex];
                    if (!tempPaymentOrderNo) {
                        this.isGetAllList = true;
                        break;
                    }
                    paymentOrderNos.push(tempPaymentOrderNo);
                    this.loadingIndex++;
                }

                if (paymentOrderNos.length == 0) {
                    resolve();
                    return;
                }

                let params = {}, apiUrl = '';

                // 贈送訂單
                if (this.isGiftOrder) {
                    params.orderNos = paymentOrderNos;
                    apiUrl = '/api/order/get_gift_subscribe_detail_order_info';
                }
                // 自用訂單
                else {
                    params.courseId = this.expandingOrderItem.courses[0].id;
                    params.orderNos = paymentOrderNos;
                    apiUrl = '/api/order/get_course_order_info';
                }

                this.$httpRequest.post(apiUrl, params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result && !this.$_.isEmpty(result)) {
                                for (let orderNo of paymentOrderNos) {
                                    let tempPayment = result[orderNo];
                                    if (tempPayment) {
                                        let tempUnit = 1,
                                            tempPricePerUnit = tempPayment.course_price,
                                            tempPlanName = '';

                                        // 使用訂閱方案購買
                                        if (tempPayment.price_name) {
                                            tempUnit = tempPayment.unit;
                                            tempPricePerUnit = tempPayment.price_per_unit;
                                            tempPlanName = tempPayment.price_name.substring(0, 4);
                                        }
                                        // 整理exchange_list內的參數
                                        let exchangeList = [];
                                        if (this.isGiftOrder) {
                                            tempPayment.exchange_list.forEach( el => {
                                                exchangeList.push({
                                                    code: el.code,
                                                    isExchanged: el.is_exchanged
                                                })
                                            });
                                        }

                                        this.paymentDetailsList.push({
                                            orderNo: orderNo,
                                            device: tempPayment.device,
                                            isPurchased: tempPayment.is_purchased,  // true: 付款成功  false: 付款失敗
                                            isRefunded: tempPayment.is_refunded,  // true: 退款成功  false: 無退款
                                            isUnsubscribed: tempPayment.is_unsubscribed,  // true: 已取消訂閱  false: 尚未取消訂閱
                                            isSubscribing: tempPayment.is_subscribing,  // true: 訂閱中  false: 已終止
                                            orderDate: tempPayment.order_date,
                                            courseState: tempPayment.course_state,
                                            unit: +tempUnit,
                                            pricePerUnit: +tempPricePerUnit,
                                            planName: tempPlanName,
                                            currency: tempPayment.currency,  // 幣值
                                            discountType: tempPayment.discount_type,  // 0: 折扣日期  1: 折扣價錢
                                            discount: +tempPayment.discount,
                                            paidAmount: +tempPayment.order_price,  // 實付價格
                                            expiredDate: tempPayment.expired_date,  // 下期扣款日 or 到期日期
                                            // 贈送訂單
                                            exchangeList: exchangeList,  // 兌換代碼列表
                                            originalTotalPrice: tempPayment.originalTotalPrice // 原始總金額
                                        });
                                    }
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getPurchasedPaymentInfo() {
            this.paymentDetailsList.push({
                orderNo: this.expandingOrderItem.orderNo,
                device: this.expandingOrderItem.device,
                isPurchased: this.expandingOrderItem.isPurchased,  // true: 付款成功  false: 付款失敗
                isRefunded: this.expandingOrderItem.isRefunded,  // true: 退款成功  false: 無退款
                orderDate: this.expandingOrderItem.orderDate,
                currency: this.expandingOrderItem.currency,  // 幣值
                discount: this.expandingOrderItem.discount,
                paidAmount: this.expandingOrderItem.paidAmount,  // 實付價格
                // 贈送訂單
                exchangeList: this.expandingOrderItem.exchangeList,  // 兌換代碼列表
                originalTotalPrice: this.expandingOrderItem.originalTotalPrice // 原始總金額
            });

            if (!this.isFirstLoaded) {
                this.isFirstLoaded = true;
                this.$emit('firstLoaded');
            }
        },
        openUnsubscribePromptDialogue(item) {
            // 設定正在取消訂閱的訂單資訊
            this.activeUnsubscribeOrder = item;

            $('#unsubscribePromptDialogue').modal('show');
        },
        copyExchangeCode(index) {
            let responseToast = this.orderUtil.copyExchangeCode(index);
            this.$store.dispatch('common/setAlert', responseToast);
        },
        getExchangeCodeFile(orderNo) {
            let params = {
                orderNo: orderNo
            }
            this.$httpRequest.get('/api/order/get_exchange_code', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let url = response.data.result;
                        let fileLink = document.createElement('a');
                        fileLink.href = url;
                        fileLink.setAttribute('download', 'exchange_code_' + orderNo + '.csv');
                        fileLink.click();
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}

// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)
// components
const LoginEntry = resolve => require(["@/components/account/login_entry.vue"], resolve)
const PwdForm = resolve => require(["@/components/account/pwd_form.vue"], resolve)
const VerifyForm = resolve => require(["@/components/account/verify_form.vue"], resolve)

export default {
    components: {
        Dialogue,
        LoginEntry,
        PwdForm,
        VerifyForm
    },
    data: function() {
        return {
            id: 'loginDialogue',

            activeState: '',

            accountType: '',
            account: '',
            pwdFormType: '',
            loginFail: false,
            verifyType: '',
            verificationCode: '',

            isPostingApi: {
                generalLogin: false,  // 登入一般帳號
            }
        }
    },
    methods: {
        dialogueLoadedHandler() {
            // 一開啟彈窗時
            $(`#${this.id}`).on('show.bs.modal', () => {
                this.activeState = 'auth';
            });
            // 關閉彈窗後
            $(`#${this.id}`).on('hidden.bs.modal', () => {
                this.activeState = '';
            });
        },

        loginSuccess() {
            $('#loginDialogue').modal('hide');
            this.$store.dispatch('common/getUserInfo');
            this.$store.dispatch('common/setAlert', { msg: '登入成功', status: 'success' });
        },
        registerSuccess() {
            $('#loginDialogue').modal('hide');
            this.$store.dispatch('common/getUserInfo');
            this.$store.dispatch('common/setAlert', { msg: '完成註冊', status: 'success' });
        },

        enterGeneralEntry(accType, acc, isUser) {
            this.accountType = accType;
            this.account = acc;
            this.changeToLoginState('', isUser);
        },

        generalLogin(pwd) {
            this.loginFail = false;

            let params = {
                type: this.accountType,
                account: this.account,
                pwdUUID: pwd,
                device: 'web'
            };

            this.isPostingApi.generalLogin = true;

            this.$httpRequest.post('/api/user/general_login', params)
                .then(response => {
                    this.isPostingApi.generalLogin = false;

                    if (response.data.state == 'OK') {
                        // 登入成功, 關閉彈窗
                        this.loginSuccess();
                    }
                    if (response.data.state == 'ERROR') {
                        let errorType = response.data.error_type;

                        switch (errorType) {
                            // 密碼錯誤
                            case 'password':
                                this.loginFail = true;
                                break;
                            // 已註冊但尚未驗證成功, 需導向帳號驗證頁面
                            case 'validated':
                                this.changeToVerifyState('auth');
                                break;
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.generalLogin = false;
                    console.error('Catched Error:', error);
                });
        },

        changeToAuthState() {
            this.activeState = 'auth';
        },
        changeToLoginState(code = '', isUser = true) {
            this.verificationCode = code;

            // 依據條件定義密碼輸入表單的類型
            if (this.verificationCode) {
                this.pwdFormType = 'resetPWD';
            } else {
                this.pwdFormType = isUser ? 'login' : 'register';
            }

            this.activeState = 'login';
        },
        changeToVerifyState(type) {
            this.verifyType = type;
            this.activeState = 'verifyaccount';
        }
    }
}

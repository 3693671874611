export default {
    props: {
        redirectBtnText: {
            type: String,
            default: ''
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        finishedOrderItemList() {
            return this.$store.state.order.finishedOrderItemList;
        },
        redirectCoursePage() {
            return courseAlias => this.$route.params.state === 'success' ? `/course/${courseAlias}/home` : `/course/${courseAlias}`;
        }
    }
}

export default {
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        appDownload() {
            return this.$store.state.common.appDownload;
        },
        groupList() {
            let list = [];
            // isOpenNewTab 為 true 時, 則使用 <a> tag 開分頁方式開啟連結
            list = [
                // 關於
                {
                    id: 1,
                    title: '關於加分吧',
                    children: [
                        {
                            text: '產品介紹',
                            imageUrl: null,
                            imageStyle: {},
                            url: '/introduction',
                            isOpenNewTab: false,
                            isShow: true
                        },
                        {
                            text: '建議與問題回報',
                            imageUrl: null,
                            imageStyle: {},
                            url: this.$route.path == '/feedback' ? '/feedback?p=https://' + location.host + '/feedback' : '/feedback?p=https://' + location.host + this.$route.fullPath,
                            isOpenNewTab: false,
                            isShow: true
                        },
                        {
                            text: '操作指南',
                            imageUrl: null,
                            imageStyle: {},
                            url: 'https://premium.learnmode.net/course/317/home',
                            isOpenNewTab: true,
                            isShow: true
                        },
                        {
                            text: '如何快速上手-學生篇',
                            imageUrl: null,
                            imageStyle: {},
                            url: 'https://youtu.be/bkTcI9npRSE',
                            isOpenNewTab: true,
                            isShow: true
                        },
                        {
                            text: '如何快速上手-老師篇',
                            imageUrl: null,
                            imageStyle: {},
                            url: 'https://youtu.be/mT5AI75Pyjw',
                            isOpenNewTab: true,
                            isShow: true
                        }
                    ]
                },
                // 條款與政策
                {
                    id: 2,
                    title: '條款與政策',
                    children: [
                        {
                            text: '服務條款',
                            imageUrl: null,
                            imageStyle: {},
                            url: '/terms',
                            isOpenNewTab: false,
                            isShow: true
                        },
                        {
                            text: '隱私權政策',
                            imageUrl: null,
                            imageStyle: {},
                            url: '/privacy',
                            isOpenNewTab: false,
                            isShow: true
                        },
                        {
                            text: '購買暨服務契約',
                            imageUrl: null,
                            imageStyle: {},
                            url: '/agreement',
                            isOpenNewTab: false,
                            isShow: true
                        }
                    ]
                },
                // 相關連結
                {
                    id: 3,
                    title: '相關連結',
                    children: [
                        {
                            text: '學習吧',
                            imageUrl: null,
                            imageStyle: {},
                            url: 'https://www.learnmode.net/',
                            isOpenNewTab: true,
                            isShow: true
                        }
                    ]
                },
                // 與我聯絡
                {
                    id: 4,
                    title: '與我聯絡',
                    children: [
                        {
                            text: '週一至週五 9:00~17:00',
                            imageUrl: null,
                            imageStyle: {},
                            url: '',
                            isOpenNewTab: false,
                            isShow: true
                        },
                        {
                            text: '0800-557885',
                            imageUrl: null,
                            imageStyle: {},
                            url: '',
                            isOpenNewTab: false,
                            isShow: true
                        },
                        {
                            text: 'LearnMode.Pre@via.com.tw',
                            imageUrl: null,
                            imageStyle: {},
                            url: 'mailto:LearnMode.Pre@via.com.tw', // 若為mail，isOpenNewTab = true
                            isOpenNewTab: true,
                            isShow: true
                        }
                    ]
                },
                // App下載
                {
                    id: 5,
                    title: 'App下載',
                    children: [
                        {
                            text: null,
                            imageUrl: this.appDownload.iOS.image,
                            imageStyle: { width: '111px', height: '33px' },
                            url: this.appDownload.iOS.link,
                            isOpenNewTab: true,
                            isShow: true
                        },
                        {
                            text: null,
                            imageUrl: this.appDownload.Android.image,
                            imageStyle: { width: '111px', height: '33px' },
                            url: this.appDownload.Android.link,
                            isOpenNewTab: true,
                            isShow: true
                        }
                    ]
                }
            ]

            list.forEach(el => {
                el.children = el.children.filter(link => link.isShow);
            });

            return list;
        },
        linksList() {
            let list = [];
            list = [
                {
                    id: 1,
                    imageUrl: require('@/assets/image/third_party/F_facebook.png'),
                    url: 'https://pse.is/LearnmodeFB3jleh9',
                    isShow: true
                },
                {
                    id: 2,
                    imageUrl: require('@/assets/image/third_party/F_instagram.png'),
                    url: 'https://pse.is/LearnmodeIG3nd8hm',
                    isShow: true
                },
                {
                    id: 3,
                    imageUrl: require('@/assets/image/third_party/F_youtube.png'),
                    url: 'https://www.youtube.com/@-fx9rc',
                    isShow: true
                }
            ]
            list = list.filter(link => link.isShow);
            return list;
        }
    }
}

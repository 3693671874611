// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    components: {
        Dialogue
    },
    data: function() {
        return {
            audioElem: new Audio(),
            playPromise: null,
            playingSoundItem: {},

            isPostingApi: {
                updateMarkedWord: false,  // 收藏/取消收藏單字
            }
        }
    },
    computed: {
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        activeSearchedWord: {
            get() {
                return this.$store.state.article.activeSearchedWord;
            },
            set(value) {
                this.$store.commit('article/setActiveSearchedWord', value);
            }
        },
        dictionaryResult: {
            get() {
                return this.$store.state.article.dictionaryResult;
            },
            set(value) {
                this.$store.commit('article/setDictionaryResult', value);
            }
        },
        isShowDictResultDialogue: {
            get() {
                return this.$store.state.article.isShowDictResultDialogue;
            },
            set(value) {
                this.$store.commit('article/setIsShowDictResultDialogue', value);
            }
        }
    },
    mounted: function() {
        // 開啟字典完整釋義彈窗後
        $('#dictResultDialogue').on('shown.bs.modal', () => {
            $('#dictResultDialogueContent').scrollTop(0);  // 卷軸置頂
        });

        // 關閉字典完整釋義彈窗後
        $('#dictResultDialogue').on('hidden.bs.modal', () => {
            this.pauseAudio();  // 停止音檔播放

            this.activeSearchedWord = { id: null };
            this.dictionaryResult = {};
            this.isShowDictResultDialogue = false;
        });
    },
    methods: {
        playPronunciationAudio(item) {
            if (item.isPlayingSound) {
                return;
            }

            if (!item.pronunciationAudioFile) {
                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                return;
            }

            if (item != this.playingSoundItem) {
                this.playingSoundItem.isPlayingSound = false;
            }
            this.playingSoundItem = item;

            this.audioElem.src = item.pronunciationAudioFile;
            if (this.audioElem.paused) {
                this.playPromise = this.audioElem.play();

                // 判斷音檔來源如果無效, 需提示為無效的音檔來源
                if (this.playPromise && this.playPromise !== undefined) {
                    this.playPromise
                        .catch((error) => {
                            if (error.name === 'NotSupportedError') {
                                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                            }
                            console.log('catched error: ' + error);
                        });
                }
            }
            this.audioElem.onplaying = () => {
                item.isPlayingSound = true;
            };
            this.audioElem.onended = () => {
                item.isPlayingSound = false;
            };
        },
        pauseAudio() {
            if (this.playPromise && this.playPromise !== undefined) {
                this.playPromise
                    .then(() => {
                        this.audioElem.pause();
                        this.playingSoundItem.isPlayingSound = false;
                    })
                    .catch((error) => {
                        console.log('catched error: ' + error);
                    });
            }
        },
        async updateMarkedWord() {
            if (this.isPostingApi.updateMarkedWord) {
                return;
            }

            let payload = {
                articleId: this.$route.params.id
            }

            this.isPostingApi.updateMarkedWord = true;
            await this.$store.dispatch('article/updateMarkedWord', payload);
            this.isPostingApi.updateMarkedWord = false;
        }
    }
}

// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    props: ['accountType', 'account'],
    components: {
        Dialogue
    },
    data: function() {
        return {
            // 0: 帳號資訊  1: 帳號綁定  2: 帳號驗證  3: 重設密碼
            viewType: 2,

            // 密碼
            password: '',
            confirmedPassword: '',

            // 帳號驗證
            authCode: [...new Array(6)].map(() => ''),
            isDisableAuthBtn: true,  // 是否 disable '帳號驗證' 的確認按鈕
            isDisableSendAuthCodeBtn: false,  // 是否 disable 重新傳送代碼按鈕
            countdownTimer: 0,  // 倒數計時器(秒)
            countdownTimerProcedure: null,  // 倒數計時器的 interval id

            isPostingApi: {
                sendAuthCode: false,  // 取得驗證碼
                authAccount: false,  // 輸入驗證碼以驗證帳號
                resetPassword: false,  // 重設密碼
            }
        }
    },
    computed: {
        checkPasswordFormatList() {
            let list = [
                {
                    id: 1,
                    text: '長度為6-20字元',
                    isChecked: this.password && (this.password.length >= 6 && this.password.length <= 20)
                },
                // {
                //     id: 2,
                //     text: '包含一個英文字母',
                //     isChecked: this.password && /[a-zA-Z]/.test(this.password)
                // },
                {
                    id: 3,
                    text: '包含一個數字',
                    isChecked: this.password && /[0-9]/.test(this.password)
                }
            ]
            return list;
        },
        isDisableSetPasswordBtn() {
            let isPasswordFormatError = false;
            this.checkPasswordFormatList.some(el => {
                if (!el.isChecked) {
                    isPasswordFormatError = true;
                    return true;
                }
            });

            return isPasswordFormatError || (this.confirmedPassword !== this.password);
        }
    },
    mounted: function() {
        // 開啟帳號資訊彈窗後, focus 第一個輸入框
        $('#accountInfoDialogue').on('shown.bs.modal', () => {
            switch (this.viewType) {
                case 1:
                    $('#inputPassword').focus();
                    break;
                case 2:
                    this.sendAuthCode();
                    $('#inputAuthCode0').focus();
                    break;
            }
        });

        // 關閉帳號資訊彈窗後, 初始設定值
        $('#accountInfoDialogue').on('hidden.bs.modal', () => {
            this.viewType = 2;

            this.password = '';
            this.confirmedPassword = '';

            this.authCode = [...new Array(6)].map(() => '');

            // 清除計時器
            clearInterval(this.countdownTimerProcedure);
            this.countdownTimerProcedure = null;
        });
    },
    beforeDestroy: function() {
        // 清除計時器
        clearInterval(this.countdownTimerProcedure);
        this.countdownTimerProcedure = null;
    },
    methods: {
        handleAuthCodeInput(action) {
            switch (action) {
                case 'forward':
                    this.authCode.some((el, i) => {
                        if (!el) {
                            $(`#inputAuthCode${i}`).focus();
                            this.isDisableAuthBtn = true;
                            return true;
                        } else {
                            this.isDisableAuthBtn = false;
                            return false;
                        }
                    });
                    break;
                case 'backward':
                    var activeDOMId = document.activeElement.id;
                    var activeIndex = activeDOMId[activeDOMId.length - 1];
                    var lastActiveIndex = activeIndex - 1;
                    // 正在 focus 的輸入框沒有值
                    if (!this.authCode[activeIndex]) {
                        // 前一個 index 在 array 第一個位置時, 則 focus 原本的位置
                        if (lastActiveIndex < 0) {
                            $(`#inputAuthCode${activeIndex}`).focus();
                        }
                        // 前一個 index 尚在 authCode array 範圍內時, 則 focus 前一個位置
                        else {
                            $(`#inputAuthCode${lastActiveIndex}`).focus();
                        }
                    }
                    break;
            }
        },
        sendAuthCode() {
            if (this.isPostingApi.sendAuthCode) {
                return;
            }

            let params = {
                type: this.accountType,
                account: this.account,
                authType: 'resetPWD'
            }

            this.isPostingApi.sendAuthCode = true;

            this.$httpRequest.post('/api/user/re_sent_auth_code', params)
                .then(response => {
                    this.isPostingApi.sendAuthCode = false;

                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                    if (response.data.state == 'ERROR') {
                        if (this.$_.has(response.data, 'sec')) {
                            this.isDisableSendAuthCodeBtn = true;
                            this.initCountdownTimer(response.data.sec);
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.sendAuthCode = false;
                    console.log('catched error: ' + error);
                });
        },
        initCountdownTimer(sec) {
            this.countdownTimer = sec;
            if (this.countdownTimer < 0) {
                return;
            }

            this.countdownTimerProcedure = setInterval(() => {
                this.countdownTimer--;
                if (this.countdownTimer == 0) {
                    clearInterval(this.countdownTimerProcedure);
                    this.countdownTimerProcedure = null;
                    this.isDisableSendAuthCodeBtn = false;
                }
            }, 1000);
        },
        authAccount() {
            if (this.isDisableAuthBtn || this.isPostingApi.sendAuthCode || this.isPostingApi.authAccount) {
                return;
            }

            let params = {
                authCode: this.authCode.join(''),
                type: this.accountType,
                account: this.account,
                authType: 'resetPWD',
                device: 'web'
            }

            this.isPostingApi.authAccount = true;

            this.$httpRequest.post('/api/user/auth_account', params)
                .then(response => {
                    this.isPostingApi.authAccount = false;

                    if (response.data.state == 'OK') {
                        this.viewType = 3;
                        this.$nextTick(() => {
                            $('#inputPassword').focus();
                        });
                    }
                })
                .catch(error => {
                    this.isPostingApi.authAccount = false;
                    console.log('catched error: ' + error);
                });
        },

        resetPassword() {
            if (this.isDisableSetPasswordBtn || this.isPostingApi.resetPassword) {
                return;
            }

            let params = {
                account: this.account,
                type: this.accountType,
                password: this.password,
                authCode: this.authCode.join('')
            }

            this.isPostingApi.resetPassword = true;

            this.$httpRequest.post('/api/user/reset_password', params)
                .then(response => {
                    this.isPostingApi.resetPassword = false;

                    if (response.data.state == 'OK') {
                        // 重設密碼成功後會重置驗證碼, 需初始相關設定值
                        this.isDisableSendAuthCodeBtn = false;
                        this.countdownTimer = 0;
                        // 清除計時器
                        clearInterval(this.countdownTimerProcedure);
                        this.countdownTimerProcedure = null;

                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        $('#accountInfoDialogue').modal('hide');
                    }
                })
                .catch(error => {
                    this.isPostingApi.resetPassword = false;
                    console.log('catched error: ' + error);
                });
        }
    }
}
